/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaLocationDot, FaWhatsapp } from 'react-icons/fa6';
import { IconButton } from '@mui/material';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import '../styles/footer.css';

function Footer() {
  const { t } = useTranslation();
  const menuItems = [
    { text: 'home', id: 'home' },
    { text: 'about', id: 'about' },
    { text: 'portfolio', id: 'portfolio' },
    { text: 'team', id: 'team' },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="footer">
        <div className="footer-content">
          <div className="menu-footer">
            <h1 className="h1-menu-footer">{t('footer.menu')}</h1>
            <hr />
            <ul>
              {menuItems.map((item) => (
                <li key={item.id}>
                  <Link
                    to={item.id}
                    spy
                    smooth
                    offset={isMobile ? -80 : -20}
                    duration={500}
                    className="link-footer"
                    key={item.id}
                  >
                    {t(`menu.${item.text}`).toUpperCase()}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="contatos-footer">
            <h1 className="h1-contatos-footer">{t('footer.contacts')}</h1>
            <hr />
            <ul>
              <li>
                <a
                  className="link-whatsapp"
                  href="https://api.whatsapp.com/send?phone=5584996177872&text=Olá,%20estou%20animado%20para%20criar%20um%20site%20incrível%20com%20sua%20ajuda!%20Poderia%20me%20fornecer%20mais%20informações%20sobre%20seus%20serviços?"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton className="contatos-icon">
                    <FaWhatsapp className="contato-icone" />
                  </IconButton>
                  {t('footer.whatsapp')}
                </a>
              </li>
              <li>
                <a
                  className="link-email"
                  href="mailto:seuemail@example.com?subject=Vamos%20criar%20um%20site%20fantástico!&body=Olá,%20estou%20muito%20interessado%20em%20seus%20serviços%20de%20desenvolvimento%20de%20sites%20e%20adoraria%20saber%20mais.%20Vamos%20trabalhar%20juntos%20para%20criar%20algo%20fantástico!"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton className="contatos-icon">
                    <FaEnvelope className="contato-icone" />
                  </IconButton>
                  {t('footer.email')}
                </a>
              </li>
              <li>
                <IconButton className="contatos-icon">
                  <FaLocationDot className="contato-icone" />
                </IconButton>
                <span className="endereco">{t('footer.address')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bar">
        <p>{t('footer.copyright')}</p>
      </div>
    </>
  );
}

export default Footer;
