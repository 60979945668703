import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import CustomModalImage from './CustomModalImage';
import '../styles/portfolio.css';

import laptopImage1 from '../images/Laptop-lifeline.png';
import phoneImage1 from '../images/Phone-lifeline.png';
import fullDesktopImage1 from '../images/desktop-lifeline-full.png';
import fullMobileImage1 from '../images/phone-lifeline-full.jpg';
import laptopImage2 from '../images/Laptop-bracami.png';
import phoneImage2 from '../images/Phone-bracami.png';
import fullDesktopImage2 from '../images/desktop--bracami-full.png';
import fullMobileImage2 from '../images/mobile-bracami-full.png';
import Avatar12 from '../images/avatar14.png';
import LandingContext from '../context/LandingContext';

const projects = [
  {
    id: 1,
    desktopImage: laptopImage1,
    mobileImage: phoneImage1,
    fullDesktop: fullDesktopImage1,
    fullMobile: fullMobileImage1,
    className: 'project-1',
    desktopClass: 'desktop-image-1',
    mobileClass: 'mobile-image-1',
    nameBrand: 'Lifeline ECO',
  },
  {
    id: 2,
    desktopImage: laptopImage2,
    mobileImage: phoneImage2,
    fullDesktop: fullDesktopImage2,
    fullMobile: fullMobileImage2,
    className: 'project-2',
    desktopClass: 'desktop-image-2',
    mobileClass: 'mobile-image-2',
    nameBrand: 'Brazão, Castro & Miranda Advogados',
  },
];

const itemVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

function Portfolio() {
  const { setIsModalOpen } = useContext(LandingContext);
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <motion.div
      id="portfolio"
      className="portfolio"
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.2,
            duration: 0.5,
            delay: 0.4,
          },
        },
      }}
    >
      <motion.h1 className="portfolio-h1" variants={itemVariants}>
        {t('portfolio.title')}
      </motion.h1>
      <motion.div className="portfolio-content" variants={itemVariants}>
        <motion.div className="projects">
          {projects.map((project, index) => (
            <motion.div
              key={project.id}
              className={project.className}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={{
                hidden: { opacity: 0, y: -50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: index * 0.6 },
                },
              }}
            >
              <CustomModalImage
                small={project.desktopImage}
                large={project.fullDesktop}
                alt={t('portfolio.desktopImageAlt', { id: project.id })}
                className={`desktop-image ${project.desktopClass}`}
                onOpen={handleOpenModal}
                onClose={handleCloseModal}
              />
              <CustomModalImage
                small={project.mobileImage}
                large={project.fullMobile}
                alt={t('portfolio.mobileImageAlt', { id: project.id })}
                className={`mobile-image ${project.mobileClass}`}
                onOpen={handleOpenModal}
                onClose={handleCloseModal}
              />
            </motion.div>
          ))}
        </motion.div>
        <motion.div className="portfolio-img-container" variants={itemVariants}>
          <motion.img
            src={Avatar12}
            alt={t('portfolio.avatarAlt')}
            className="portfolio-avatar"
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Portfolio;
