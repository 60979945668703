/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Lightbox } from 'react-modal-image';

function CustomModalImage({
  small,
  large,
  alt = '',
  className = '',
  onOpen = () => {},
  onClose = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    onOpen();
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      <img
        src={small}
        alt={alt}
        className={className}
        onClick={handleOpen}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleOpen();
          }
        }}
        role="button"
        tabIndex={0}
      />
      {isOpen && (
        <Lightbox
          medium={large}
          large={large}
          alt={alt}
          onClose={handleClose}
        />
      )}
    </>
  );
}

CustomModalImage.propTypes = {
  small: PropTypes.string.isRequired,
  large: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default CustomModalImage;
