/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ReactTyped } from 'react-typed';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import homeImg from '../images/home_img.png';
import '../styles/home.css';

function Home() {
  const { ref, inView } = useInView({ triggerOnce: true });
  const { t } = useTranslation();

  return (
    <motion.section
      className="home"
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 2.5 }}
      ref={ref}
    >
      <motion.div
        className="text-container"
        initial={{ opacity: 0, y: -20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
        transition={{ duration: 0.5, staggerChildren: 0.5, delay: 0.5 }}
      >
        <motion.h1 className="name" style={{ color: '#9faeff' }}>
          {t('home.name')}
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          <ReactTyped
            className="typed-text"
            strings={[t('home.job')]}
            typeSpeed={100}
            backSpeed={50}
            loop
            cursorChar="|"
            showCursor
          />
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
        transition={{ duration: 0.5, staggerChildren: 0.5, delay: 0.9 }}
        className="home-img-div"
      >
        <motion.img src={homeImg} alt={t('home.imgAlt')} className="home-img" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
        transition={{ duration: 0.5, delay: 1.1 }}
        className="home-mobile-btn-div"
      >
        <motion.a
          className="link-whatsapp"
          href="https://api.whatsapp.com/send?phone=5584996177872&text=Olá,%20estou%20animado%20para%20criar%20um%20site%20incrível%20com%20sua%20ajuda!%20Poderia%20me%20fornecer%20mais%20informações%20sobre%20seus%20serviços?"
          target="_blank"
          rel="noreferrer"
        >
          <motion.button className="home-mobile-btn btn-43">
            {t('contact.hireMe')}
          </motion.button>
        </motion.a>
      </motion.div>
    </motion.section>
  );
}

export default Home;
