/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LandingContext from './LandingContext';

function LandingProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const values = useMemo(
    () => ({
      isModalOpen,
      setIsModalOpen,
    }),
    [isModalOpen]
  );

  return (
    <LandingContext.Provider value={values}>{children}</LandingContext.Provider>
  );
}

LandingProvider.propTypes = {
  children: PropTypes.object,
}.isRequired;

export default LandingProvider;
