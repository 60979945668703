/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../images/avatar8.png';
import '../styles/header.css';
import LandingContext from '../context/LandingContext';

const StyledAppBar = styled(AppBar)({
  width: '90%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  height: '80px',
  backgroundColor: 'transparent !important',
  transition:
    'background-color 0.3s ease-in-out, height 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important',
  margin: '0 auto',
  left: '50%',
  transform: 'translateX(-50%)',
});

const ToolbarComponents = styled(motion.div)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '0 auto',
  marginLeft: '0px',
});

const itemVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isModalOpen } = useContext(LandingContext);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { t } = useTranslation();

  const menuItems = [
    { text: t('menu.home'), id: 'home' },
    { text: t('menu.about'), id: 'about' },
    { text: t('menu.portfolio'), id: 'portfolio' },
    { text: t('menu.team'), id: 'team' },
  ];

  const handleMenuToggle = () => {
    setMenuOpen((prevState) => !prevState); // Usando o valor anterior para garantir a alternância correta
  };

  const renderDesktopMenu = () => (
    <motion.div
      className="desktop-menu"
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.2,
            duration: 0.5,
            delay: 0.4,
          },
        },
      }}
    >
      {menuItems.map((item) => (
        <motion.div key={item.id} variants={itemVariants}>
          <Link
            to={item.id}
            spy
            smooth
            offset={-20}
            duration={500}
            className="menu-item"
          >
            {item.text.toUpperCase()}
          </Link>
        </motion.div>
      ))}
      <motion.div variants={itemVariants}>
        <LanguageSwitcher />
      </motion.div>
    </motion.div>
  );

  const renderMobileMenu = () => (
    <motion.div
      className={`navigation ${menuOpen ? 'open' : ''}`}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      <input
        type="checkbox"
        className="navigation__checkbox"
        id="nav-toggle"
        checked={menuOpen}
        onChange={handleMenuToggle}
        aria-label="Toogle menu"
      />
      <label
        htmlFor="nav-toggle"
        className="navigation__button"
        aria-label="Toogle navigation menu"
      >
        <span className="navigation__icon" aria-hidden="true" />
      </label>
      <div className="navigation__background" />

      <nav className="navigation__nav" role="navigation">
        <ul className="navigation__list">
          {menuItems.map((item) => (
            <li className="navigation__item" key={item.id}>
              <Link
                to={item.id}
                spy
                smooth
                offset={-60}
                duration={500}
                className="navigation__link"
                onClick={() => setMenuOpen(false)}
              >
                {item.text.toUpperCase()}
              </Link>
            </li>
          ))}
          <li className="navigation__item">
            <LanguageSwitcher />
          </li>
        </ul>
      </nav>
    </motion.div>
  );

  return (
    <StyledAppBar
      className={`header ${isModalOpen ? 'hidden' : ''}`}
      elevation={0}
    >
      <Toolbar className="toolbar">
        <ToolbarComponents
          className="toolbar-components"
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          <motion.div className="logo" variants={itemVariants}>
            <motion.img
              src={logo}
              alt={t('logo.alt')}
              className="logo-img"
              variants={itemVariants}
            />
          </motion.div>
          {!isMobile && renderDesktopMenu()}
          {isMobile && renderMobileMenu()}
        </ToolbarComponents>
        {!isMobile && (
          <a
            className="link-whatsapp"
            href="https://api.whatsapp.com/send?phone=5584996177872&text=Olá,%20estou%20animado%20para%20criar%20um%20site%20incrível%20com%20sua%20ajuda!%20Poderia%20me%20fornecer%20mais%20informações%20sobre%20seus%20serviços?"
            target="_blank"
            rel="noreferrer"
          >
            <motion.button
              className="btn41-43 btn-43"
              type="button"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              {t('contact.hireMe')}
            </motion.button>
          </a>
        )}
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
