import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('pt');

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    setCurrentLanguage(lng);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: 'text.primary',
          padding: '0px',
          borderRadius: '0',
          fontFamily: 'Lato, sans-serif',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: 'rgba(146, 227, 169, 0.2)',
            borderRadius: '0',
          },
        }}
      >
        <Typography
          variant="body1"
          component="span"
          sx={{
            marginRight: 0,
            fontFamily: 'Lato, sans-serif',
            fontWeight: 'bold',
          }}
        >
          {currentLanguage.toUpperCase()}
        </Typography>
        <ArrowDropDownIcon
          sx={{
            color: 'text.primary',
            transition: 'color 0.3s ease',
            '&:hover': {
              color: '#000',
            },
          }}
        />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            color: 'text.primary',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(146, 227, 169, 0.2)',
            },
          }}
          onClick={() => changeLanguage('pt')}
        >
          PT
        </MenuItem>
        <MenuItem
          sx={{
            color: 'text.primary',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(146, 227, 169, 0.2)',
            },
          }}
          onClick={() => changeLanguage('es')}
        >
          ES
        </MenuItem>
        <MenuItem
          sx={{
            color: 'text.primary',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(146, 227, 169, 0.2)',
            },
          }}
          onClick={() => changeLanguage('en')}
        >
          EN
        </MenuItem>
      </Menu>
    </div>
  );
}

export default LanguageSwitcher;
