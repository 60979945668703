/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import './i18n';
import './App.css';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Team from './components/Team';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="app-content">
        <Home />
        <About />
        <Portfolio />
        <Team />
      </div>
      <div className="app-footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
