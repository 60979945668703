import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import TeamCards from './TeamCards';
import '../styles/team.css';

// Importa todas as imagens para todos os idiomas
import FrontCard1EN from '../images/dayv-a-card-1-EN.png';
import BackCard1EN from '../images/dayv-b-card-1-EN.png';
import FrontCard2EN from '../images/dayv-a-card-2-EN.png';
import BackCard2EN from '../images/dayv-b-card-2-EN.png';
import FrontCard3EN from '../images/ze-a-card-EN.png';
import BackCard3EN from '../images/ze-b-card-EN.png';

import FrontCard1PT from '../images/dayv-a-card-1-PT.png';
import BackCard1PT from '../images/dayv-b-card-1-PT.png';
import FrontCard2PT from '../images/dayv-a-card-2-PT.png';
import BackCard2PT from '../images/dayv-b-card-2-PT.png';
import FrontCard3PT from '../images/ze-a-card-PT.png';
import BackCard3PT from '../images/ze-b-card-PT.png';

import FrontCard1ES from '../images/dayv-a-card-1-ES.png';
import BackCard1ES from '../images/dayv-b-card-1-ES.png';
import FrontCard2ES from '../images/dayv-a-card-2-ES.png';
import BackCard2ES from '../images/dayv-b-card-2-ES.png';
import FrontCard3ES from '../images/ze-a-card-ES.png';
import BackCard3ES from '../images/ze-b-card-ES.png';

const itemVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

function Team() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const { t, i18n } = useTranslation();
  const [teamData, setTeamData] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState('pt');

  useEffect(() => {
    const images = {
      en: {
        frontCard1: FrontCard1EN,
        backCard1: BackCard1EN,
        frontCard2: FrontCard2EN,
        backCard2: BackCard2EN,
        frontCard3: FrontCard3EN,
        backCard3: BackCard3EN,
      },
      pt: {
        frontCard1: FrontCard1PT,
        backCard1: BackCard1PT,
        frontCard2: FrontCard2PT,
        backCard2: BackCard2PT,
        frontCard3: FrontCard3PT,
        backCard3: BackCard3PT,
      },
      es: {
        frontCard1: FrontCard1ES,
        backCard1: BackCard1ES,
        frontCard2: FrontCard2ES,
        backCard2: BackCard2ES,
        frontCard3: FrontCard3ES,
        backCard3: BackCard3ES,
      },
    };

    const currentImages = images[currentLanguage] || images.en;

    setTeamData([
      {
        id: 1,
        frontCard: currentImages.frontCard1,
        backCard: currentImages.backCard1,
      },
      {
        id: 3,
        frontCard: currentImages.frontCard3,
        backCard: currentImages.backCard3,
      },
      {
        id: 2,
        frontCard: currentImages.frontCard2,
        backCard: currentImages.backCard2,
      },
    ]);
  }, [currentLanguage]); // Dependência no idioma

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setCurrentLanguage(lng);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  return (
    <motion.div
      id="team"
      className="team"
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.2,
            duration: 0.5,
            delay: 0.4,
          },
        },
      }}
    >
      <motion.h1 className="team-h1" variants={itemVariants}>
        {t('team.title')}
      </motion.h1>
      <motion.div variants={itemVariants} className="team-container-cards">
        <TeamCards teamData={teamData} />
      </motion.div>
    </motion.div>
  );
}

export default Team;
