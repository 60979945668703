import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'primereact/carousel';
import { Card } from 'primereact/card';
import FlipCard from './FlipCard';

function TeamCards({ teamData }) {
  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleClick = (index) => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };

  const itemTemplate = (card, index) => (
    <FlipCard
      key={card.id}
      frontContent={
        <Card className="team-card-item">
          <img src={card.frontCard} alt="Front" className="image-front" />
        </Card>
      }
      backContent={
        <Card className="team-card-item">
          <img src={card.backCard} alt="Back" className="image-back" />
        </Card>
      }
      isFlipped={flippedIndex === index}
      onClick={() => handleClick(index)}
    />
  );

  return (
    <div className="team-cards">
      <Carousel
        value={teamData}
        itemTemplate={itemTemplate}
        numVisible={3}
        numScroll={1}
        className="custom-carousel"
        contentClassName="custom-carousel-content"
        circular
        responsiveOptions={[
          {
            breakpoint: '1301px',
            numVisible: 3,
            numScroll: 1,
          },
          {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1,
          },
          {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1,
          },
        ]}
      />
    </div>
  );
}

TeamCards.propTypes = {
  teamData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      frontCard: PropTypes.string.isRequired,
      backCard: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TeamCards;
