import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';
import '../styles/contact-form.css';

export default function ContactForm() {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setSnackbar({
        open: true,
        message: t('contactForm.captchaErrorMessage'),
        severity: 'error',
      });
      return;
    }

    const templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_CONFIRMATION_TEMPLATE_ID,
        {
          ...templateParams,
          to_email: formData.email,
        },
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      setSnackbar({
        open: true,
        message: t('contactForm.successMessage'),
        severity: 'success',
      });
      setFormData({ name: '', email: '', message: '' });
      setRecaptchaValue(null);
      setOpen(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: t('contactForm.errorMessage'),
        severity: 'error',
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div>
      <motion.button
        variant="contained"
        onClick={() => setOpen(true)}
        className="btn41-43 btn-43"
      >
        {t('contactMe.email')}
      </motion.button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="contact-form-title"
        aria-describedby="contact-form-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
          role="presentation"
          tabIndex={0}
          sx={{
            position: 'relative',
            width: '400px',
            bgcolor: 'background.paper',
            padding: 3,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'text.primary',
            }}
          >
            <CloseIcon />
          </IconButton>
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography id="contact-form-title" variant="h6" component="h2">
              {t('contactForm.title')}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={t('contactForm.name')}
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('contactForm.email')}
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="message"
              label={t('contactForm.message')}
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
            />

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setRecaptchaValue}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="btn41-43 btn-43"
            >
              {t('contactForm.sendMessage')}
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        action={
          <Button color="inherit" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%', zIndex: 9999 }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
