/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import 'react-vertical-timeline-component/style.min.css';
import Avatar12 from '../images/avatar10.png';
import DomIcon from '../images/dom.svg';
import RestApiIcon from '../images/api.svg';
import emailjsIcon from '../images/emailjs.svg';
import '../styles/about.css';
import ContactForm from './ContactForm';

const skillsData = [
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-plain-wordmark.svg',
    description: 'skills.html',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-plain-wordmark.svg',
    description: 'skills.css',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-plain.svg',
    description: 'skills.javascript',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg',
    description: 'skills.react',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-line-wordmark.svg',
    description: 'skills.nodejs',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/framermotion/framermotion-original-wordmark.svg',
    description: 'skills.framermotion',
  },
  { skill: '', icon: DomIcon, description: 'skills.dom' },
  {
    skill: '',
    icon: RestApiIcon,
    description: 'skills.restapi',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-plain-wordmark.svg',
    description: 'skills.git',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original-wordmark.svg',
    description: 'skills.github',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bash/bash-plain.svg',
    description: 'skills.bash',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vercel/vercel-original-wordmark.svg',
    description: 'skills.vercel',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/netlify/netlify-plain-wordmark.svg',
    description: 'skills.netlify',
  },
  {
    skill: '',
    icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/photoshop/photoshop-plain.svg',
    description: 'skills.photoshop',
  },
  {
    skill: '',
    icon: emailjsIcon,
    description: 'skills.emailjs',
  },
];

const itemVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

function About() {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <motion.div
      className="about"
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.2,
            duration: 0.5,
            delay: 0.4,
          },
        },
      }}
    >
      <motion.div className="about-div-avatar" variants={itemVariants}>
        <motion.img
          src={Avatar12}
          alt={t('about.avatarAlt')}
          className="about-image-avatar"
        />
      </motion.div>
      <motion.div className="text-container-about">
        <motion.h1 className="about-h1" variants={itemVariants}>
          {t('about.title')}
        </motion.h1>
        <motion.p className="about-p" variants={itemVariants}>
          {t('about.description.part1')}
        </motion.p>
        <motion.p className="about-p" variants={itemVariants}>
          {t('about.description.part2')}
        </motion.p>
        <motion.p className="about-p" variants={itemVariants}>
          {t('about.description.part3')}
        </motion.p>
        <motion.div variants={itemVariants} className="about-btns">
          <ContactForm />
          <a
            className="link-whatsapp"
            href="https://api.whatsapp.com/send?phone=5584996177872&text=Olá,%20estou%20animado%20para%20criar%20um%20site%20incrível%20com%20sua%20ajuda!%20Poderia%20me%20fornecer%20mais%20informações%20sobre%20seus%20serviços?"
            target="_blank"
            rel="noreferrer"
          >
            <motion.button className="btn41-43 btn-43" type="button">
              {t('contactMe.whatsapp')}
            </motion.button>
          </a>
        </motion.div>
        <motion.h2 className="about-h2" variants={itemVariants}>
          {t('about.skills')}
        </motion.h2>
        <motion.div className="about-skills-container">
          {skillsData.map(({ skill, icon, description }) => (
            <motion.div
              className="skill-card"
              key={description}
              variants={itemVariants}
            >
              <motion.img
                className="skill-icon"
                src={icon}
                alt={`${skill} icon`}
              />
              <motion.p className="skill-name">{skill}</motion.p>
              <motion.p className="skill-description">
                {t(description)}
              </motion.p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default About;
